<template>
  <section id="processes">
    <div ref="element" class="bg-secondary py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-[#044E4F] font-bold tracking-tighter text-2xl mb-4">
          Digital Transformation Process
        </h2>

        <div class="mb-6">
          The digital transformation of your facility begins with having a
          defined process workflow that will allow for the creation of an
          eventual digital twin roadmap for your facility(s). Green BIM believes
          the digital transformation of data to achieve a digital twin of your
          facility consists of a 5-step process:
        </div>

        <div
          class="md:flex"
          @mouseenter="paused = true"
          @mouseleave="paused = false"
        >
          <div class="relative flex-column text-sm font-medium md:me-2">
            <ul ref="stepsTabList" class="sticky top-[10px] mdx:mb-8">
              <li
                v-for="(tab, index) in tabs"
                :key="tab.id"
                @click="selectTab(tab.id)"
              >
                <a
                  :ref="
                    el => {
                      if (el) tabRefs[tab.id].value = el;
                    }
                  "
                  href="#"
                  :class="[
                    'rounded-lg md:rounded-tr-[0px] md:rounded-br-[0px] inline-flex items-center px-4 py-3 hover:text-gray-900 bg-gray-50 shadow hover:bg-primary/25 w-full mb-2',
                    {
                      'text-gray-900 bg-primary/50 md:bg-gray-100':
                        currentTab === tab.id,
                    },
                    {
                      'text-gray-300 pointer-events-none bg-gray-50 w-full dark:bg-gray-800 dark:text-gray-600':
                        tab.disabled,
                    },
                  ]"
                >
                  {{ index + 1 }}. {{ tab.name }}
                </a>
              </li>
              <li
                class="absolute transition-all duration-200 ease-in-out mt-0 top-0 right-[-11px] hidden md:block w-0 h-0 border-r-0 border-t-[22px] border-t-transparent border-l-[11px] border-l-primary border-b-[22px] border-b-transparent z-10 "
                :style="indicatorStyle"
              />
            </ul>
          </div>

          <transition-group
            name="slide-fade"
            tag="div"
            class="flex-1 rounded-lg md:rounded-tl-[0px] bg-white/50 shadow"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <div v-if="currentTab === 'tab-1'" key="tab-1" class="py-3 px-5">
              <div class="flex flex-col mdx:flex-row">
                <div class="mdx:w-1/2 flex items-center justify-center mb-4">
                  <ol class="list-disc custom-marker-dot">
                    <li>What is the condition of your data?</li>
                    <li>How many attributes for each asset?</li>
                    <li>What are your current model formats?</li>
                    <li>Do you have any use cases to develop?</li>
                    <li>How do you want to use your data?</li>
                    <li>What would you like the DT to look like?</li>
                    <li>Do you have key performance indicators?</li>
                    <li>What are the skills of Technicians?</li>
                    <li>What are your business processes?</li>
                    <li>Current Standardizations of your data?</li>
                  </ol>
                </div>

                <div class="mdx:w-1/2 flex items-center justify-center mb-4">
                  <img
                    src="../assets/images/steps/Digital_Twin_Assessment.webp"
                    alt="Digital Twin Assessment"
                  >
                </div>
              </div>
            </div>

            <div v-if="currentTab === 'tab-2'" key="tab-2" class="py-3 px-5">
              <div class="flex flex-col mdx:flex-row">
                <div class="mdx:w-1/2 flex items-center justify-center mb-4">
                  <img
                    src="../assets/images/steps/Model_Data_Cleaning_1.webp"
                    alt="Model Data Cleaning"
                  >
                </div>

                <div class="mdx:w-1/2 flex items-center justify-center mb-4">
                  <img
                    src="../assets/images/steps/Model_Data_Cleaning_2.webp"
                    alt="Model Data Cleaning"
                  >
                </div>
              </div>
            </div>

            <div v-if="currentTab === 'tab-3'" key="tab-3" class="py-3 px-5">
              <img
                src="../assets/images/steps/Aggregation_Visualization.webp"
                alt="Aggregation & Visualization"
              >
            </div>

            <div v-if="currentTab === 'tab-4'" key="tab-4" class="py-3 px-5">
              <img
                src="../assets/images/steps/loT_connection_Work_Orders.webp"
                alt="loT connection / Work Orders"
              >
            </div>

            <div
              v-if="currentTab === 'tab-5'"
              key="tab-5"
              class="h-full py-3 px-5"
            >
              <div class="flex flex-col mdx:flex-row">
                <div
                  class="mdx:w-1/2 flex items-center justify-center mdx:pr-4 mb-4"
                >
                  <ol class="custom-check-list">
                    <li>Root Cause Analysis</li>
                    <li>Optimal Configurations</li>
                    <li>Increased Uptime</li>
                    <li>Risk Reduction</li>
                    <li>Continuous Improvement</li>
                  </ol>
                </div>

                <div class="mdx:w-3/5 flex items-center justify-center mb-4">
                  <img
                    src="../assets/images/steps/Simulation_Analysis_Machine_Learning.webp"
                    alt="Simulation, Analysis, Machine Learning (Al)"
                  >
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>

    <div class="py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="flex flex-col mdx:flex-row space-y-20 mdx:space-y-0">
          <div class="mdx:w-1/2 mx-4">
            <img
              class="mx-auto max-w-[330px] mb-4"
              src="../assets/images/steps/GREENHECK.webp"
              alt="GREENHECK"
            >

            <div class="mb-4">
              All information is critical to the creation of a Digital Twin of
              your existing facilities, allowing the users to access this data
              at the click of a button. All asset data is digitized, allowing
              the user the ability to compare installed asset data with building
              management systems such as IoT sensor data.
            </div>
          </div>

          <div class="flex flex-col mdx:w-1/2 mx-4">
            <img
              class="mx-auto max-w-[330px] mdx:order-last mb-4"
              src="../assets/images/steps/Data-Process-Pic1-1.webp"
              alt="Data Process Pic1"
            >

            <div class="mb-4">
              All design documents, construction submittals, commissioning data,
              and all turnover data is captured and attached to the asset
              visualization, whether it be 2D or 3D to create a digital
              representation of your real-world building.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { useElementVisibility } from '@vueuse/core';

const tabs = [
  {
    id: 'tab-1',
    name: 'Digital Twin Assessment',
    color: '#ebf3d7'
  },
  {
    id: 'tab-2',
    name: 'Model & Data Cleaning (DT, DX)',
    content: 'Tabs content 2',
    color: '#c3cdaa'
  },
  {
    id: 'tab-3',
    name: 'Aggregation & Visualization',
    content: 'Tabs content 3',
    color: '#9aa67c'
  },
  {
    id: 'tab-4',
    name: 'loT connection / Work Orders',
    content: 'Tabs content 4',
    color: '#72804f'
  },
  {
    id: 'tab-5',
    name: 'Simulation, Analysis, Machine Learning (Al)',
    color: '#4a5a22'
  }
];

const element = ref(null);

const isVisible = useElementVisibility(element, { threshold: 0.01 });
watch(isVisible, value => {
  if (!value) {
    paused.value = true;
  }
});

const currentTab = ref('tab-1');

const tabRefs = tabs.reduce((acc, tab) => {
  acc[tab.id] = ref(null);
  return acc;
}, {});

const indicatorStyle = ref({});
const paused = ref(false);

watch(currentTab, () => {
  nextTick(updateIndicatorPosition);
});

onMounted(() => {
  nextTick(updateIndicatorPosition);

  const interval = setInterval(() => {
    if (!paused.value) {
      const currentIndex = tabs.findIndex(tab => tab.id === currentTab.value);
      const nextIndex = (currentIndex + 1) % tabs.length;
      currentTab.value = tabs[nextIndex].id;
    }
  }, 5000);

  onUnmounted(() => {
    clearInterval(interval);
  });
});

function beforeEnter(el) {
  el.style.opacity = 0;
  el.style.transform = 'translateX(40px)';
}

function enter(el, done) {
  el.style.transition = 'transform 0.4s ease-out, opacity 0.4s ease-out';
  el.style.opacity = 1;
  el.style.transform = 'translateX(0)';
  done();
}

function leave(el, done) {
  el.style.transition = 'transform 0.4s ease-out, opacity 0.4s ease-out';
  el.style.opacity = 0;
  el.style.transform = 'translateX(-40px)';
  done();
}

function updateIndicatorPosition() {
  const currentTabElement = tabRefs[currentTab.value].value;
  if (currentTabElement) {
    const { offsetTop: tabOffsetTop, clientHeight } = currentTabElement;
    indicatorStyle.value = {
      top: `${tabOffsetTop}px`,
      height: `${clientHeight}px`
    };
  }
}

function selectTab(tabId) {
  if (!tabs.find(item => item.id === tabId)?.disabled) {
    currentTab.value = tabId;
  }
}
</script>
