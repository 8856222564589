<template>
  <section class="partner-info">
    <div class="bg-white py-24 sm:pt-32 sm:pb-24 px-6 max-w-[800px] mx-auto">
      <h2
        class="text-[#044E4F] text-center font-bold tracking-tighter text-2xl mb-4"
      >
        Siemens
      </h2>

      <img
        :src="siemens_logo"
        alt="Siemens"
        class="max-w-[400px] m-auto mb-6"
      >

      <div class="text-md text-[#044E4F] mt-4 mb-4">
        Building X – Leap into the future
      </div>

      <p class="mb-4">
        A scalable digital building platform to digitalize, manage and optimize
        building operations, allowing for enhanced user experience, increased
        performance and improved sustainability.
      </p>

      <p class="mb-4">
        Siemens Building X suite offers many benefits for your facility:
      </p>

      <ul class="custom-check-list mb-6">
        <li>
          Unlocking the full potential – Benefit by monitoring and managing
          buildings with different lifespans and systems – on a single platform.
        </li>

        <li class="mt-2">
          Deconstructing complexity – One source of truth. With Building X,
          access building data from multiple sources, disciplines, and systems
          on a single cloud-based platform without compromising cybersecurity.
        </li>

        <li class="mt-2">
          Empowering people – No matter your role in building operations and
          management, you’ll have the right data, at the right time and right
          place. Meeting your KPIs has never been easier.
        </li>

        <li class="mt-2">
          Performing with ease – Achieve your goals faster. The single-platform
          principle of Building X provides you with a seamless user experience
          for fast access to the data you need for informed decision-making.
        </li>

        <li class="mt-2">
          Building strong partnerships – From reducing operating costs to
          regulatory compliance, our team of building experts will help you
          achieve your goals with data-driven service programs.
        </li>

        <li class="mt-2">
          Outperforming with AI – With AI, you can detect problems before they
          occur, optimize operating costs more efficiently, and precisely
          predict tomorrow’s energy consumption with yesterday’s data.
        </li>
      </ul>

      <img :src="image" alt="siemens" class="w-full">
    </div>
  </section>
</template>

<script setup>
import siemens_logo from '../assets/images/partners/Siemens-Logo.svg';
import image from '../assets/images/partners/partners/Siemens-FM.webp';

const emit = defineEmits([ 'update:modelValue' ]);

emit('update:modelValue', null);
</script>
