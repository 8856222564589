<template>
  <section class="partner-info">
    <div class="bg-white py-24 sm:pt-32 sm:pb-24 px-6 max-w-[800px] mx-auto">
      <h2
        class="text-[#044E4F] text-center font-bold tracking-tighter text-2xl mb-4"
      >
        UrsaLeo
      </h2>

      <img
        :src="ursaleo_logo"
        alt="UrsaLeo"
        class="max-w-[400px] max-h-[120px] m-auto mb-6"
      >

      <div class="text-md text-[#044E4F] mt-4 mb-4">
        Design, Plan, Operate and Maintain in One Interface – A Central Hub to
        Access all your Data!
      </div>

      <h4 class="text-md font-bold text-[#044E4F] mt-4 mb-4">During Design</h4>

      <p class="mb-4">
        We utilize the fantastic power of NVIDIA’s Omniverse to deliver digital
        twins fully hosted in the cloud. Our digital twins are rendered on the
        latest high-end servers and streamed to any device that supports a
        browser, typically at 120 FPS. Architects, designers, planners, and
        engineering companies can use the digital twin to simulate, plan,
        collaborate and showcase the facility. Over forty different 3D design
        packages seamlessly sync into a single twin alongside accurate
        geospatial data. Real time ray-traced graphics show accurate
        reflections, textures, colors and shadows, changing as the light changes
        – under the users control.
      </p>

      <h4 class="text-md font-bold text-[#044E4F] mt-4 mb-4">
        During Construction
      </h4>

      <p class="mb-4">
        During construction, all participants can continue collaborating through
        the twin. Documents are added to the twin by clicking on objects within
        the scene so manuals, warranties and close out documents, can be
        attached to the relevant part of the twin. We interface with common
        construction management software such as Procore. At closeout, the twin
        can be delivered to the client with all needed data integrated into the
        twin.
      </p>

      <h4 class="text-md font-bold text-[#044E4F] mt-4 mb-4">
        During Operation
      </h4>

      <p class="mb-4">
        After delivery, the twin can be integrated with Building Management
        Systems (BMS), IoT systems, SCADA systems, real time location systems
        (RTLS) and work order systems. Asset information, documentation and
        maintenance history can be retrieved simply by clicking objects in the
        twin. Key metrics can be highlighted using advanced data visualizations,
        highlighting energy usage or frequency of warnings and errors from
        pieces of equipment. If simulation was used, the models can be compared
        to actual operation, highlighting inefficiencies and bottlenecks.
        Complete building dashboards are created using a massive library of
        pre-designed graphs and charts. We have integrated our twins with
        industry-leading analytics software for those wanting to search for
        real-time deeper insights.
      </p>

      <img
        :src="image1"
        alt="UrsaLeo being used for animation and collaboration."
        class="w-full"
      >

      <p class="mb-6">UrsaLeo being used for animation and collaboration.</p>

      <img
        :src="image2"
        alt="UrsaLeo being used to track worker safety and monitor incident tracking."
        class="w-full"
      >

      <p>
        UrsaLeo being used to track worker safety and monitor incident tracking.
      </p>
    </div>
  </section>
</template>

<script setup>
import ursaleo_logo from '../assets/images/partners/UrsaLeo-Logo.webp';
import image1 from '../assets/images/partners/partners/Ursaleo-Colaboration-Pic-for-Website.webp';
import image2 from '../assets/images/partners/partners/Ursaleo-Colaboration-Pic-for-Website-2.webp';

const emit = defineEmits([ 'update:modelValue' ]);

emit('update:modelValue', null);
</script>
