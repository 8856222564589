<template>
  <section
    id="about"
    class="relative bg-white overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36"
  >
    <div class="container">
      <div class="mx-auto max-w-7xl lg:px-8">
        <div class="lg:grid lg:grid-cols-12">
          <div
            class="relative z-10 lg:col-span-5 lg:col-start-1 lg:row-start-1 lg:py-32 -mb-12"
          >
            <div
              class="mx-auto max-w-md px-16 sm:max-w-3xl lg:max-w-none lg:p-0"
            >
              <img
                class="rounded-3xl object-cover object-center shadow-2xl"
                :src="urlAboutUs"
                alt="about us"
              >
            </div>
          </div>

          <div
            class="relative bg-[#D5F5F0] lg:col-span-10 lg:col-start-3 lg:row-start-1 lg:grid lg:grid-cols-10 lg:items-center rounded-3xl"
          >
            <div
              class="absolute inset-0 hidden overflow-hidden rounded-3xl lg:block"
              aria-hidden="true"
            >
              <svg
                class="absolute bottom-full left-full -translate-x-2/3 translate-y-1/3 transform xl:bottom-auto xl:top-0 xl:translate-y-0"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-white/50"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>

              <svg
                class="absolute top-full -translate-x-1/3 -translate-y-1/3 transform xl:-translate-y-1/2"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-white/50"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>

            <div
              class="relative mx-auto max-w-md space-y-6 px-6 py-16 sm:max-w-3xl sm:pt-[64px] sm:pb-20 lg:col-span-6 lg:col-start-4 lg:max-w-none"
            >
              <h2 class="text-3xl font-bold tracking-tight lg:-ml-20">About Us</h2>

              <p class="text-lg">
                At Green BIM, we are revolutionizing the AEC industry with our cutting-edge Building Information Modeling (BIM) and Digital Twin services. Our mission is to deliver superior buildings for our clients while driving down costs and optimizing efficiency. We understand that leveraging the data gtathered during design, engineering and construction can not just be an evolutionary step forward; it’s can be a revolutionary leap that transforms how we conceive, construct, and manage buildings.
              </p>

              <p class="text-lg">
                We harness BIM for comprehensive Asset Management, Facilities Management, and to provide real-time insights and reports into our clients’ portfolios. Our approach ensures that every object in our models is intelligent, aware of its purpose, and automatically integrated into project documentation, from door schedules to scope reports.
              </p>

              <p class="text-lg">
                Our services extend beyond traditional BIM with offerings like Digital Modular Construction and Digital Twin technologies, which are reshaping the future of construction and facility management. We are committed to making the building industry more efficient, focusing on cost and time savings both in the short term and throughout the lifecycle of a building.
              </p>

              <p class="text-lg">
                Join us in embracing the Green BIM Advantage – where sustainability meets innovation.
              </p>

              <router-link
                class="btn-primary inline-block mt-8"
                to="/#contacts"
              >
                Join Us
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import urlAboutUs from "../assets/images/aboutUs.jpeg";

export default {
  data() {
    return {
      urlAboutUs
    };
  }
};
</script>
