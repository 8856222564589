<template>
  <section id="hero" class="mb-40">
    <div v-motion-pop-visible-once :delay="200" class="container space-y-10 text-center mt-20">
      <h1 class="text-[#044E4F] text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
        Making the building industry more efficient through the use of digital technology
      </h1>
    </div>
  </section>
</template>

<script setup>

</script>
