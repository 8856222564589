<template>
  <section class="partner-info">
    <div class="bg-white py-24 sm:pt-32 sm:pb-24 px-6 max-w-[800px] mx-auto">
      <h2
        class="text-[#044E4F] text-center font-bold tracking-tighter text-2xl mb-4"
      >
        Langan Engineering
      </h2>

      <img
        :src="langan_logo"
        alt="Langan Engineering"
        class="max-w-[400px] max-h-[120px] m-auto mb-6"
      >

      <div class="text-md text-[#044E4F] mt-4 mb-4">
        Langan provides an integrated mix of engineering and environmental
        consulting services in support of land development projects, corporate
        real estate portfolios, and the energy industry. Our clients include
        developers, property owners, public agencies, corporations,
        institutions, and energy companies around the world.
      </div>

      <h4 class="text-md font-bold text-[#044E4F] mt-4 mb-4">OUR MISSION</h4>

      <p class="mb-4">
        Our mission is to contribute to the success of our clients by providing
        an unmatched combination of our people’s technical excellence, practical
        experience, and responsiveness.
      </p>

      <h4 class="text-md font-bold text-[#044E4F] mt-4 mb-4">OUR VISION</h4>

      <p class="mb-6">
        Our vision is to be recognized by our clients, employees, and
        competition as being “the best” engineering and environmental consulting
        firm. To be “the best” we will hire only people who can demonstrate the
        potential to grow; constantly seek to transfer our values and technical
        knowledge internally; relentlessly pursue improvement in the quality of
        our work; and know our clients and their needs better than any other
        firm in our field.
      </p>

      <img
        :src="image1"
        alt="Example of Photogrammetry taken using Leica P series Laser Scanner"
        class="w-full"
      >

      <p class="mb-6">
        Example of Photogrammetry taken using Leica P series Laser Scanner
      </p>

      <img
        :src="image2"
        alt="System verification using laser scanning overlay against a BIM Model"
        class="w-full"
      >

      <p class="mb-6">
        System verification using laser scanning overlay against a BIM Model
      </p>

      <img
        :src="image3"
        alt="Laser Scan of exterior of building to create detail for BIM Model and for Verification of locations of assets"
        class="w-full"
      >

      <p>
        Laser Scan of exterior of building to create detail for BIM Model and
        for Verification of locations of assets
      </p>
    </div>
  </section>
</template>

<script setup>
import langan_logo from '../assets/images/partners/Langan-Logo.webp';
import image1 from '../assets/images/partners/partners/Langan-Photogrametry.webp';
import image2 from '../assets/images/partners/partners/Langan-Verification-JPG-1.webp';
import image3 from '../assets/images/partners/partners/Circa3.webp';

const emit = defineEmits([ 'update:modelValue' ]);

emit('update:modelValue', null);
</script>
