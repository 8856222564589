import { ViteSSG } from 'vite-ssg';
import App from './App.vue';
import routes from './routes';
import { FontAwesomeIcon } from './fontawesome';
import { MotionPlugin } from '@vueuse/motion';

import './assets/style/main.scss';

export const createApp = ViteSSG(
  App,
  { routes },
  ({ app }) => {
    // install plugins
    app.component('FontAwesomeIcon', FontAwesomeIcon);
    app.use(MotionPlugin);
  }
);
