<template>
  <button
    v-for="(service, index) in services"
    :key="service.title"
    :ref="service.id"
    class="flex items-center text-left w-full rounded-lg px-4 py-3 shadow hover:bg-primary/25 hover:text-gray-900 mb-2 transition duration-300 cursor-pointer"
    :class="localActiveIndex === index + 1 ? 'text-gray-900 bg-primary/50 bg-gray-100' : 'bg-gray-50'"
    @click="toggle(index + 1)"
  >
    <div class="flex-shrink-0 h-[70px] w-[15vw] bg-no-repeat bg-center bg-cover bg-white rounded-lg mr-8" :style="{ backgroundImage: 'url(' + service.imageUrl + ')' }" />

    <h3 class="text-lg font-semibold leading-6 tracking-tight text-[#044E4F]">
      {{ service.title }}
    </h3>
  </button>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  services: {
    type: Array,
    required: true
  },
  activeIndex: {
    type: Number,
    default: null
  }
});

const emit = defineEmits([ 'update:activeIndex', 'scroll' ]);

const localActiveIndex = ref(props.activeIndex);

function toggle(index) {
  localActiveIndex.value = localActiveIndex.value === index ? null : index;
  emit('update:activeIndex', localActiveIndex.value);
  emit('scroll');
}

watch(
  () => props.activeIndex,
  newVal => {
    localActiveIndex.value = newVal;
  }
);
</script>
