<template>
  <transition
    enter-active-class="transition ease-in-out duration-200 transform"
    enter-from-class="-translate-y-full"
    enter-to-class="translate-y-0"
    leave-active-class="transition ease-in-out duration-200 transform"
    leave-from-class="translate-y-0"
    leave-to-class="-translate-y-full"
    appear
  >
    <the-navbar :active-link="activeLink" />
  </transition>

  <div
    id="scroll-container"
    ref="target"
    :key="$route.path"
    class="fixed top-0 bottom-0 pt-navbar w-full h-full overflow-y-auto flex flex-col"
  >
    <div class="grow">
      <router-view v-slot="{ Component }">
        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="transform opacity-0"
          enter-to-class="transform opacity-100"
          leave-active-class="transition duration-200 ease-out"
          leave-from-class="transform opacity-100"
          leave-to-class="transform opacity-0"
          mode="out-in"
          appear
        >
          <component :is="Component" v-model="activeLink" />
        </transition>
      </router-view>
    </div>

    <transition
      enter-active-class="transition ease-in-out duration-200 transform"
      enter-from-class="-translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transition ease-in-out duration-200 transform"
      leave-from-class="translate-y-0"
      leave-to-class="-translate-y-full"
      appear
    >
      <the-footer class="grow-0 shrink-0" />
    </transition>
  </div>

  <dev-tool class="fixed top-4 left-4 z-50" /> <!-- FIXME: Remove this line before building for production -->
</template>

<script setup>
import { ref } from 'vue';
import TheNavbar from './components/TheNavbar.vue';
import TheFooter from './components/TheFooter.vue';

import DevTool from './components/DevTool.vue'; // FIXME: Remove this line before building for production

const activeLink = ref(null);
</script>
