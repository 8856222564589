import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faLinkedin,
  faYoutube,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';

import {
  faBars,
  faEnvelope,
  faArrowDown,
  faLock,
  faFaceDizzy,
  faPills,
  faPizzaSlice
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(
  faLinkedin,
  faBars,
  faEnvelope,
  faArrowDown,
  faLock,
  faFaceDizzy,
  faPills,
  faPizzaSlice,
  faYoutube,
  faXTwitter
);

export { FontAwesomeIcon };
