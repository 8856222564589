<template>
  <section id="services">
    <div class="bg-[#D5F5F0] py-24 md:py-32">
      <div
        :class="[
          'mx-auto max-w-7xl px-6',
          {'grid lg:grid-cols-10 gap-x-8 gap-y-20 lg:px-8': isDesktop}
        ]"
      >
        <div v-if="!isDesktop" class="mb-6">
          <h2
            class="text-[#044E4F] font-bold tracking-tighter text-2xl mb-4"
          >
            Digital Twin Services
          </h2>

          <p class="my-6">
            Data is your most important asset, don’t waste it!
          </p>

          <services-menu-mobile
            :services="services"
            :active-index="activeIndex"
            @update:active-index="toggle"
            @scroll="scrollToServicesContent"
          />
        </div>

        <div
          id="services-content"
          class="transition-all scroll-my-24"
          :class="activeIndex != null ? 'lg:col-span-8' : 'lg:col-span-3'"
        >
          <div :class="activeIndex != null ? '' : 'sticky top-[10px]'">
            <template v-if="isDesktop">
              <h2
                class="text-2xl font-bold tracking-tight text-[#044E4F] sm:text-4xl"
              >
                Digital Twin Services
              </h2>

              <p class="my-6 text-lg leading-8 text-gray-600">
                Data is your most important asset, don’t waste it!
              </p>

              <router-link
                class="btn bg-[#007274] text-[#D5F5F0] hover:bg-[#016364] inline-block mb-10"
                to="/#contacts"
              >
                Contact Us
              </router-link>
            </template>

            <transition-group
              name="slide-fade"
              tag="div"
              class="flex-1 rounded-lg bg-white/50 shadow"
            >
              <div v-if="activeIndex === 1" key="1" class="py-3 px-5">
                <h3
                  class="text-xl font-bold tracking-tight text-[#044E4F] mb-6"
                >
                  Digital Data (COBie) Cleaning and Turnover Documentation
                  Delivery
                </h3>

                <p class="mb-4">
                  The Digital Twin process starts with good data and the ability
                  to access your building’s data digitally and the ability to
                  use that data (basis of information) for comparative purposes.
                  Green BIM provides data gathering, data assembly, and
                  solutions for Digital Twin technologies for building owners.
                  There are a lot of resources that need to be brought together
                  to create a Digital Twin solution, and Green BIM is here to
                  help.
                </p>

                <p class="mb-4">
                  Green BIM uses the latest data standardization called COBie
                  (Construction Operations & Building information exchange)
                  developed by the US Army Corps of Engineers. This
                  standardization process allows your data to be portable to
                  other building management systems, without the need to rework
                  the data with every new software within your building’s
                  ecosystem. The example below shows what a typical data
                  extraction would look like from a building information model
                  or BIM model. Generic information about the asset is usually
                  what you will get during the construction process such as
                  Mechanical Equipment_16, being a 15 ton Unit. The type of HVAC
                  equipment may or may not be the one installed in the owner’s
                  building, and space information usually is not available due
                  to the way the model was initially setup. Other hints of bad
                  data are missing descriptions of the asset and numerical tag
                  numbers that have no descriptive properties:
                </p>

                <j-x-slider
                  :before-image="slider_1_before"
                  :after-image="slider_1_after"
                  class="mb-4"
                />

                <p class="mb-4">Data Comparison before and after cleaning</p>
              </div>

              <div v-if="activeIndex === 2" key="2" class="py-3 px-5">
                <h3
                  class="text-xl font-bold tracking-tight text-[#044E4F] mb-6"
                >
                  3D Model Cleaning & Preparation
                </h3>

                <h4 class="text-md font-bold text-[#044E4F] mb-4">
                  3D Laser Scanning to 2D Floor Plan Creation & Cleaning
                </h4>

                <p class="mb-4">
                  The visualization of your facility can come in both 2D
                  floorplans and 3D building information modeling (BIM). The
                  objects within these models along with their meta data
                  (individual asset attributes) are the most important part of
                  creating a digital twin. Green BIM will help your team define
                  the model elements and meta data to be included for eventual
                  visualization in your digital twin.
                </p>

                <img
                  class="mb-8"
                  src="../assets/images/services/YouBIM-2D-Work-order-Capture-2-1.webp"
                  alt="YouBIM-2D-Work-order-Capture-2"
                >

                <h4 class="text-md font-bold text-[#044E4F] mb-4">
                  3D Model Preparation for Digital Twin Platform
                </h4>

                <p class="mb-4">
                  Preparing the model so it performs optimally within the
                  selected Digital Twin Platform is of utmost importance in the
                  model preparation process. What objects will be displayed and
                  which objects will not depends on user preferences, and each
                  Digital Twin platform comes with a different set of
                  visualization rules. Green BIM has the experience to create
                  your preferred visualization:
                </p>

                <p class="mb-4">
                  Green BIM has experience with many digital twin platforms,
                  knowing how to set up each platform to gain the optimal
                  efficiency for data visualization
                </p>

                <img
                  class="mb-8"
                  src="../assets/images/services/3D-Model-Preparation-Website-Pic-2.webp"
                  alt="3D-Model-Preparation-Website-Pic"
                >

                <h4 class="text-md font-bold text-[#044E4F] mb-4">
                  3D Model Preparation for Model Viewer Visualization
                </h4>

                <p class="mb-4">
                  Digital Twin Platforms come with a wide variety of viewing
                  options, including IFC Viewers, Unity, Unreal Engine, Autodesk
                  Forge and more. Green BIM’s team of experts will create the
                  proper visualization to fit the preferred model viewer.
                </p>

                <img
                  class="mb-8"
                  src="../assets/images/services/Ursaleo-Colaboration-Pic-for-Website-2-1.webp"
                  alt="Ursaleo-Colaboration-Pic-for-Website"
                >

                <h4 class="text-md font-bold text-[#044E4F] mb-4">
                  3D Model Data Cleaning
                </h4>

                <p class="mb-4">
                  Data within you BIM Model turnover is almost always
                  placeholder data for the construction process. Cleaning this
                  data to match your turnover submittals allows you to transfer
                  that data digitally to the Building Management System of
                  choice, while making the link between the model and data
                  portable. Green BIM will clean this model data to match
                  installed asset data, making the analyzation of asset data to
                  installed IoT sensors possible as part of your digital twin
                  process.
                </p>

                <img
                  class="mb-4"
                  src="../assets/images/services/3D-Data-Cleanign-for-Website-Pic-1.webp"
                  alt="3D-Data-Cleanign-for-Website-Pic-1"
                >
              </div>

              <div v-if="activeIndex === 3" key="3" class="py-3 px-5">
                <h3
                  class="text-xl font-bold tracking-tight text-[#044E4F] mb-6"
                >
                  Level of Detail
                </h3>

                <p class="mb-4">
                  Creating a visually pleasing representation of your facility
                  not only helps cut down on the time it takes to identify a
                  model element, but also helps the user adoption of the
                  technology. Facilities technicians are more apt to use the
                  platform if they don’t have to decipher what one block is from
                  another. Green BIM takes your Digital Twin model to the next
                  level by adding a Level of Detail (LOD) that makes the
                  visualization more lifelike to the end user.
                </p>

                <img
                  src="../assets/images/services/LOD-Explanation.webp"
                  alt="LOD-Explanation"
                >

                <hr class="my-8">

                <j-x-slider
                  :before-image="slider_2_before"
                  :after-image="slider_2_after"
                  class="mb-4"
                />

                <p class="mb-4">
                  Adding a level of detail to your digital twin model allows for
                  greater user adoption and a faster way to visualize data
                </p>
              </div>

              <div v-if="activeIndex === 4" key="4" class="py-3 px-5">
                <h3
                  class="text-xl font-bold tracking-tight text-[#044E4F] mb-6"
                >
                  Digital Asset & Systems Delivery
                </h3>

                <p class="my-6">
                  Digital system turnover is a valuable tool used by facilities
                  technicians for analysis of system data and instant recall of
                  asset data. Facility technicians can use the digital
                  information to isolate systems, perform work order management,
                  research asset information, and IoT Sensor data comparison
                  instantly on their desktop, tablet, or mobile phone.
                </p>

                <img
                  class="my-6"
                  src="../assets/images/services/YouBIM-IoT-Sensor-Supply-Air.webp"
                  alt="YouBIM-IoT-Sensor-Supply-Air"
                >

                <p class="my-6">
                  Once the system data has been collected and visualized, IoT
                  sensors can be attached to the 3D (or 2D) visualization of
                  your asset, giving the user the ability to analyze the data by
                  comparing manufacturer data, install data, and IoT sensor data
                  to get the best possible picture of the performance of an
                  asset. Eventually this data can also be used to run
                  simulations to create scenarios for use in preventative and
                  predictive maintenance:
                </p>

                <h4 class="text-md font-bold text-[#044E4F] mt-8 mb-4">
                  Digital Floor Plans
                </h4>

                <p class="mb-6">
                  In some organizations, facilities personnel have worked with
                  paper 2D plans for many years, and they prefer working in 2D.
                  By digitizing the facility’s data, Green BIM will create a
                  “clickable” digital floor plan for the delivery of project
                  data essentially making every asset accessible within seconds.
                  This makes all asset data Point & Click, cutting down on
                  research time to find meta data within turnover documents such
                  as a pdf or Excel spreadsheet, or visualizing a work order to
                  be completed.
                </p>

                <img
                  src="../assets/images/services/Digital-Floor-Plan-For-Websit-Pic-1.webp"
                  alt="Digital-Floor-Plan-For-Websit-Pic"
                >
                <p class="mb-6">
                  2D “Digital Twin” with work order integration, point and click
                  data, and full navigation ability
                </p>

                <img
                  src="../assets/images/services/YouBIM-2D-Work-order-Capture.webp"
                  alt="YouBIM-2D-Work-order-Capture"
                >
                <p class="mb-4">
                  2D Floor Plan with Preventative Maintenance work order
                  visualization
                </p>
              </div>

              <div v-if="activeIndex === 5" key="5" class="py-3 px-5">
                <h3
                  class="text-xl font-bold tracking-tight text-[#044E4F] mb-6"
                >
                  Digital Twin Assessment
                </h3>

                <p class="mb-4">
                  The Digital Twin Assessment is an important part of the
                  process workflow needed to create a working digital twin.
                  Within the assessment we generally cover four main areas:
                  Stakeholder Value Framing, Data Assessment, Meta Data
                  Assessment, and Digital Twin Roadmap. Each of these steps are
                  equally important to the overall goal of creating a company
                  specific digital twin with the process being outlined for
                  each.
                </p>

                <p class="mb-4">
                  During this initial step in the digital twin process, Green
                  BIM will uncover the answers to key questions: What is the
                  condition of your data? How many attributes would like for
                  each asset? What are your current model formats? Do you have
                  any use cases to develop? How do you want to use your data?
                  What would you like the DT to look like? What trade colors
                  would you like to use? Do you have key performance indicators?
                  What are the skills of Technicians? What are your business
                  processes? Current Standardizations of your data? How do you
                  standardize your data? And many more…
                </p>

                <img
                  class="mb-4"
                  src="../assets/images/services/Digital-Twin-Assessment-Model-Data-Review-1.webp"
                  alt="Digital-Twin-Assessment-Model-Data-Review"
                >

                <img
                  src="../assets/images/services/Data-Standardization-Abbreviations2-1.webp"
                  alt="Data-Standardization-Abbreviations"
                >

                <p class="mb-4">
                  Green BIM will standardize your data to make it “portable”
                  into other building management systems.
                </p>
              </div>

              <div v-if="activeIndex === 6" key="6" class="py-3 px-5">
                <h3
                  class="text-xl font-bold tracking-tight text-[#044E4F] mb-6"
                >
                  IoT Sensor Integration
                </h3>

                <p class="mb-4">
                  The goal of any digital twin project is to make the building
                  data available to maintenance professionals to better
                  understand their building performance. Green BIM helps our
                  clients eliminate “data silos” of information by digitizing
                  asset data and meta data, allowing multiple building systems
                  data to be compared. By digitizing the data, it allows the
                  user to compare data from construction, installation, and
                  current asset data from sensors, eliminating the need to go
                  back into documents to compare separate silos of information.
                </p>

                <img
                  class="mb-4"
                  src="../assets/images/services/AHU-Silos-of-Information.webp"
                  alt="AHU-Silos-of-Information"
                >

                <p class="mb-4">
                  Dashboards are created according to key performance
                  indicators, allowing the user to compare data digitally, thus
                  eliminating the need for search for silos of data:
                </p>

                <img
                  class="mb-4"
                  src="../assets/images/services/Morgan-One-Sensor-Display-Pic-1.webp"
                  alt="Morgan-One-Sensor-Display-Pic"
                >

                <p class="mb-4">
                  The first step in creating a digital twin is to create an
                  accurate digital data set for all your assets within your
                  facility. This is important because to do any kind of analysis
                  of your building data, you will need an accurate record of all
                  the attributes associated with that asset to compare to the
                  live data being captured from your IoT sensors. Taking it to
                  the next level, these separate “buckets” of information can
                  now be analyzed against other building management systems
                  data. This data can also be attached to the digital twin
                  platform’s work order management system for seamless
                  integration between both systems. Green BIM has just the right
                  partners to help you visualize your data and perform an
                  accurate analysis of that data using the digital twin
                  platform.
                </p>

                <img
                  class="mb-4"
                  src="../assets/images/services/IoT-For-Website-Pic-1-1.webp"
                  alt="IoT-For-Website-Pic"
                >
              </div>
            </transition-group>
          </div>
        </div>

        <div v-if="!isDesktop" class="mt-6 text-center">
          <router-link
            class="btn bg-[#007274] text-[#D5F5F0] hover:bg-[#016364] inline-block mt-8"
            to="/#contacts"
          >
            Contact Us
          </router-link>
        </div>

        <services-menu-desktop
          v-if="isDesktop"
          :services="services"
          :active-index="activeIndex"
          @update:active-index="toggle"
          @scroll="scrollToServicesContent"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';

import url1 from '../assets/images/services/hard-drive-630172_1280.webp';
import url2 from '../assets/images/services/3D-building-Model-Pic-1.webp';
import url3 from '../assets/images/services/After-Level-of-Detail-1.webp';
import url4 from '../assets/images/services/YouBIM-Systems-Pic-for-Website-1.webp';
import url5 from '../assets/images/services/Digital-Twin-Assessment-Model-Data-Review-1.webp';
import url6 from '../assets/images/services/AHU-Silos-of-Information.webp';

import slider_1_before from '../assets/images/services/COBie-Data-For-Website-Before-Pic-1-3.webp';
import slider_1_after from '../assets/images/services/COBie-Data-For-Website-After-Pic-2-3.webp';
import slider_2_before from '../assets/images/services/MCHBT-Exterior-124.webp';
import slider_2_after from '../assets/images/services/MCHBT-Front-Revit-Screenshot-3.webp';

import JXSlider from './JXSlider.vue';
import ServicesMenuMobile from './ServicesMenuMobile.vue';
import ServicesMenuDesktop from './ServicesMenuDesktop.vue';

const services = [
  {
    id: 'cobie',
    title: 'Digital Data (COBie) Cleaning and Turnover Documentation Delivery',
    imageUrl: url1,
    description:
      'The Digital Twin process starts with good data and the ability to access your building’s data digitally and the ability to use that data (basis of information) for comparative purposes.  Green BIM provides data gathering, data assembly, and solutions for Digital Twin technologies for building owners. There are a lot of resources that need to be brought together to create a Digital Twin solution, and Green BIM is here to help! Read more...'
  },
  {
    id: 'm3d',
    title: '3D Model Cleaning & Preparation',
    imageUrl: url2,
    description:
      'The visualization of your facility can come in both 2D floorplans and 3D building information modeling (BIM). The objects within these models along with their meta data (individual asset attributes) are the most important part of creating a digital twin. Green BIM will help your team define the model elements and meta data to be included for eventual visualization in your digital twin. Read more...'
  },
  {
    id: 'lofd',
    title: 'Level of Detail',
    imageUrl: url3,
    description:
      'Creating a visually pleasing representation of your facility not only helps cut down on the time it takes to identify a model element, but also helps the user adoption of the technology. Facilities technicians are more apt to use the platform if they don’t have to decipher what one block is from another. Green BIM takes your Digital Twin model to the next level by adding a Level of Detail (LOD) that makes the visualization more lifelike to the end user...'
  },
  {
    id: 'dasd',
    title: 'Digital Asset & Systems Delivery',
    imageUrl: url4,
    description:
      'Digital system turnover is a valuable tool used by facilities technicians for analysis of system data and instant recall of asset data. Facility technicians can use the digital information to isolate systems, perform work order management, research asset information, and IoT Sensor data comparison instantly on their desktop, tablet, or mobile phone. Read more...'
  },
  {
    id: 'dta',
    title: 'Digital Twin Assessment',
    imageUrl: url5,
    description:
      'The Digital Twin Assessment is an important part of the process workflow needed to create a working digital twin. Within the assessment we generally cover four main areas: Stakeholder Value Framing, Data Assessment, Meta Data Assessment, and Digital Twin Roadmap. Each of these steps are equally important to the overall goal of creating a company specific digital twin with the process being outlined for each. Read more...'
  },
  {
    id: 'iot',
    title: 'IoT Sensor Integration',
    imageUrl: url6,
    description:
      'The goal of any digital twin project is to make the building data available to maintenance professionals to better understand their building performance. Green BIM helps our clients eliminate “data silos” of information by digitizing asset data and meta data, allowing multiple building systems data to be compared. By digitizing the data, it allows the user to compare data from construction, installation, and current asset data from sensors, Read more...'
  }
];

const windowWidth = ref(null);
const isDesktop = computed(() => windowWidth.value >= 1024);

const activeIndex = ref(null);

onMounted(() => {
  window.addEventListener('resize', resizeWidth);
  resizeWidth();

  window.addEventListener('activate-service', activateServiceHandler);

  onUnmounted(() => {
    window.removeEventListener('activate-service', activateServiceHandler);
    window.removeEventListener('resize', resizeWidth);
  });
});

function resizeWidth() {
  windowWidth.value = document.body.clientWidth;
}

function activateServiceHandler(event) {
  const { serviceId } = event.detail;

  const index = services.findIndex(service => service.id === serviceId);

  if (activeIndex.value !== index + 1 && index !== -1) {
    toggle(index + 1);
  }
}

function toggle(index) {
  activeIndex.value = activeIndex.value === index ? null : index;
}

function scrollToServicesContent() {
  document.getElementById('services-content').scrollIntoView({ behavior: 'smooth' });
}
</script>
