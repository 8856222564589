<template>
  <section id="partners">
    <div class="bg-secondary py-24 sm:pt-32 sm:pb-24">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2
          class="text-[#044E4F] text-center font-bold tracking-tighter text-2xl mb-4"
        >
          <div class="text-4xl text-center mb-1">Partners</div>
          Trusted by the world’s most innovative teams
        </h2>

        <div
          class="mx-auto mt-10 flex items-stretch flex-wrap justify-center gap-x-8 gap-y-10 mb-16"
        >
          <div
            :delay="150"
            class="flex-shrink-0 xs:w-full sm:w-[33%] mdx:w-[20%] flex flex-col items-center object-contain group cursor-pointer"
            @click="$router.push('/youbim')"
          >
            <div class="pb-4 font-bold tracking-tighter text-xl">YouBim</div>
            <img
              src="../assets/images/partners/Youbim-logo.svg"
              alt="Youbim"
              class="max-w-[158px] my-auto group-hover:scale-105 transition-transform duration-150"
            >
          </div>

          <div
            :delay="150"
            class="flex-shrink-0 xs:w-full sm:w-[33%] mdx:w-[20%] flex flex-col items-center object-contain group cursor-pointer"
            @click="$router.push('/ursaleo')"
          >
            <div class="pb-4 font-bold tracking-tighter text-xl">UrsaLeo</div>
            <img
              src="../assets/images/partners/UrsaLeo-Logo.webp"
              alt="UrsaLeo"
              class="max-w-[158px] max-h-[80px] m-auto group-hover:scale-105 transition-transform duration-150"
            >
          </div>

          <div
            :delay="150"
            class="flex-shrink-0 xs:w-full sm:w-[33%] mdx:w-[20%] flex flex-col items-center object-contain group cursor-pointer"
            @click="$router.push('/siemens')"
          >
            <div class="pb-4 font-bold tracking-tighter text-xl">Siemens</div>
            <img
              src="../assets/images/partners/Siemens-Logo.svg"
              alt="Siemens"
              class="max-w-[158px] my-auto group-hover:scale-105 transition-transform duration-150"
            >
          </div>

          <div
            :delay="150"
            class="flex-shrink-0 xs:w-full sm:w-[33%] mdx:w-[20%] flex flex-col items-center object-contain group cursor-pointer"
            @click="$router.push('/langan')"
          >
            <div class="pb-4 font-bold tracking-tighter text-xl">
              Langan Engineering
            </div>
            <img
              src="../assets/images/partners/Langan-Logo.webp"
              alt="Langan Engineering"
              class="max-w-[158px] my-auto group-hover:scale-105 transition-transform duration-150"
            >
          </div>
        </div>

        <h2
          class="text-[#044E4F] text-center font-bold tracking-tighter text-2xl mb-12"
        >
          <div class="text-4xl text-center mb-1">Clients</div>
          We are proud to serve a diverse range of clients for all the Digital Twin and BIM needs
        </h2>

        <div class="flex flex-wrap -mx-4">
          <div v-for="index in 19" :key="index" class="flex-grow flex items-center justify-center mx-4 sm:mb-12 mb-8">
            <img :src="imageUrls[`url_${index}`]" :alt="`client ${index}`">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import url_1 from "../assets/images/partners/clients/client_1.webp";
import url_2 from "../assets/images/partners/clients/client_2.webp";
import url_3 from "../assets/images/partners/clients/client_3.webp";
import url_4 from "../assets/images/partners/clients/client_4.webp";
import url_5 from "../assets/images/partners/clients/client_5.webp";
import url_6 from "../assets/images/partners/clients/client_6.webp";
import url_7 from "../assets/images/partners/clients/client_7.webp";
import url_8 from "../assets/images/partners/clients/client_8.webp";
import url_9 from "../assets/images/partners/clients/client_9.webp";
import url_10 from "../assets/images/partners/clients/client_10.webp";
import url_11 from "../assets/images/partners/clients/client_11.webp";
import url_12 from "../assets/images/partners/clients/client_12.webp";
import url_13 from "../assets/images/partners/clients/client_13.webp";
import url_14 from "../assets/images/partners/clients/client_14.webp";
import url_15 from "../assets/images/partners/clients/client_15.webp";
import url_16 from "../assets/images/partners/clients/client_16.webp";
import url_17 from "../assets/images/partners/clients/client_17.webp";
import url_18 from "../assets/images/partners/clients/client_18.webp";
import url_19 from "../assets/images/partners/clients/client_19.webp";

export default {
  data() {
    return {
      imageUrls: {
        url_1,
        url_2,
        url_3,
        url_4,
        url_5,
        url_6,
        url_7,
        url_8,
        url_9,
        url_10,
        url_11,
        url_12,
        url_13,
        url_14,
        url_15,
        url_16,
        url_17,
        url_18,
        url_19
      }
    };
  }
};
</script>
