<template>
  <section id="for-modular" class="relative bg-[#007274] overflow-hidden py-20">
    <div class="container text-[#D5F5F0]">
      <div class="relative mx-auto px-10 max-w-4xl pb-8">
        <h2 class="font-bold tracking-tighter text-3xl mb-6">
          BIM for Modular
        </h2>

        <div class="text-lg">
          Building Information Modeling (BIM) is a 3D Modeling process that
          improves planning, design and construction of the buildings with this
          it benefits and portrays impact on off-site construction. It acts as
          the integration for Modular Construction based on production,
          <img
            class="mdx:float-right rounded-3xl shadow-2xl my-8 mdx:ml-8 w-full mdx:w-auto"
            :src="urlComposite"
            alt="BIM for Modular"
          >
          execution and development of prefabricated panels or volumetric
          modules. The power of BIM for modular is that it provide insight into
          pricing, scheduling and factory line planning.
          <br>
          <br>
          Off site construction stands to gain the most from BIM, yet off site
          is often the most behind in BIM. Off site companies will find they are
          lacking in areas like standards, materials integration, factory line
          integration, and computational automation. When you’ve tied your
          materials, to work results and tasks; BIM combined with automation
          tools can give you highly accurate and instant information.
        </div>
      </div>

      <div class="max-w-4xl mx-auto px-10 text-lg">
        <div
          class="bg-white p-6 rounded-3xl md:w-[70%] lg:w-2/5 -mx-8 md:mr-8 mb-8 lg:-ml-20 shadow-2xl md:float-left"
        >
          <h3
            class="text-lg font-semibold leading-6 tracking-tight text-[#044E4F] text-center mb-4"
          >
            Green BIM can help you:
          </h3>

          <ul class="custom-check-list text-auto-dark">
            <li>Develop Standard</li>
            <li>Build Building Information Models</li>
            <li>Automate the design/engineering/shop drawing process</li>
            <li>Create an integrated ERP</li>
            <li>
              Refine your model to produce digital twins for a clients
              facilities management
            </li>
          </ul>
        </div>

        Green BIM is helping off site construction companies by changing this
        paradigm to enable the realization of BIM’s full potential. Green BIMs
        method of integrated and automated technology enables production of a
        highly detailed Digital Twin model of every building. The model encodes
        all of the instructions for the building,
        <div
          class="mx-auto my-8 bg-white p-6 rounded-3xl max-w-[400px] md:w-[50%] md:ml-8 lg:w-2/5 lg:-mr-20 shadow-2xl md:float-right"
        >
          <h3
            class="text-lg font-semibold leading-6 tracking-tight text-[#044E4F] text-center mb-4"
          >
            Key benefits BIM brings to off site:
          </h3>

          <ul class="custom-check-list text-auto-dark">
            <li>Pricing</li>
            <li>Scheduling</li>
            <li>Planning</li>
            <li>Material Planning</li>
            <li>Computational Automation</li>
            <li>Digital Twin</li>
          </ul>
        </div>
        including how to manufacture it, how to assemble it, and how to most
        effectively manage it once construction is complete.
        <br>
        <br>
        Please contact us to learn how we can help you leverage BIM technology
        for off site construction.
        <router-link
          to="/#contacts"
          class="block md:inline-block md:w-1/2 text-center bg-white text-[#044E4F] font-bold hover:bg-white/80 rounded-3xl text-lg px-10 py-4 mt-8 shadow-xl"
        >
          Contact Us
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import urlComposite from '../assets/images/composite-1.gif';

export default {
  data() {
    return {
      urlComposite
    };
  }
};
</script>
