import Home from './pages/Home.vue';
import Partner_1 from './pages/Partner_1.vue';
import Partner_2 from './pages/Partner_2.vue';
import Partner_3 from './pages/Partner_3.vue';
import Partner_4 from './pages/Partner_4.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/youbim',
    component: Partner_1
  },

  {
    path: '/ursaleo',
    component: Partner_2
  },

  {
    path: '/siemens',
    component: Partner_3
  },

  {
    path: '/langan',
    component: Partner_4
  }
];
