<template>
  <footer>
    <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8 border-t border-gray-200">
      <social-links class="flex justify-center space-x-6 md:order-2" />

      <div class="mt-8 md:order-1 md:mt-0">
        <p class="text-center text-base">
          <span>&copy; {{ year }} GreenBIM</span>

          <span class="mx-5 boredr-slate-200 border-l" />

          Made in <span class="text-lg mx-1 relative top-[2px]" style="opacity: 0.6">🇺🇦</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import SocialLinks from './SocialLinks.vue';

const year = new Date().getFullYear();
</script>
