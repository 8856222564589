<template>
  <section class="partner-info">
    <div class="bg-white py-24 sm:pt-32 sm:pb-24 px-6 max-w-[800px] mx-auto">
      <h2
        class="text-[#044E4F] text-center font-bold tracking-tighter text-2xl mb-4"
      >
        YouBIM
      </h2>

      <img :src="youbim_logo" alt="Youbim" class="max-w-[400px] mx-auto mb-6">

      <p class="mb-4">
        YouBIM helps facility managers and maintenance teams to increase their
        productivity by centralizing all facilities information around a
        cloud-based, intuitive 3D representation of the property.
      </p>

      <h4 class="text-md font-bold text-[#044E4F] mt-4 mb-4">About YouBIM</h4>

      <p class="mb-4">
        YouBIM® is a Cloud-based Software (also available On-Prem), which
        extends the value of BIM [Building Information Modeling] through the
        Buildings Lifecycle by giving owners an integrated database and
        immediate access to location and asset information through an
        easy-to-navigate web-based 2D/3D-BIM interface.
      </p>

      <p class="mb-4">
        YouBIM attaches rich data and documents [PDFs, JPGs, Excels, etc.] to
        smart objects within the online Building Information Model (BIM)
        dataset. YouBIM also includes “light” Work Orders and Preventive
        Maintenance functionalities, and it seamlessly integrates with CMMS and
        CAFM systems like IBM Maximo, TMA Systems and can be custom integrated
        with SAP EAM, Infor, etc. YouBIM can also integrate with IoT/BAS/BMS
        bringing live performance data to be displayed within the YouBIM
        environment.
      </p>

      <p class="mb-4">
        YouBIM User Interface differentiates itself from other solutions in the
        industry by its simplicity and by its User Experience focused on the
        Owner and facility staff.
      </p>

      <img
        :src="image1"
        alt="Youbim Systems Overview for Website"
        class="w-full mb-6"
      >

      <j-x-slider
        :before-image="slider_1_before"
        :after-image="slider_1_after"
        class="mt-4"
      />
    </div>
  </section>
</template>

<script setup>
import youbim_logo from '../assets/images/partners/Youbim-logo.svg';
import image1 from '../assets/images/partners/partners/YouBIM-Systems-Overview-for-Website.webp';
import slider_1_before from '../assets/images/partners/partners/YouBIM-Systems-Pic-for-Website-2.webp';
import slider_1_after from '../assets/images/partners/partners/YouBIM-Systems-Pic-for-Website-3.webp';

import JXSlider from '../components/JXSlider.vue';

const emit = defineEmits([ 'update:modelValue' ]);

emit('update:modelValue', null);
</script>
