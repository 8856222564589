<template>
  <div
    class="space-y-12 divide-y divide-gray-200 transition-all"
    :class="activeIndex != null ? 'lg:col-span-2' : 'lg:col-span-7'"
  >
    <button
      v-for="(service, index) in services"
      :key="service.title"
      :ref="service.id"
      class="flex w-full border-none space-20 rounded-2xl text-left group hover:shadow-lg hover:bg-white/25 transition duration-300 cursor-pointer"
      :class="[
        activeIndex != null ? 'flex-col-reverse gap-2' : 'sm:flex-row gap-10',
        { 'shadow-lg bg-white/50': activeIndex === index + 1 },
      ]"
      @click="toggle(index + 1)"
    >
      <img
        :src="service.imageUrl"
        class="flex-none rounded-2xl object-cover group-hover:scale-90 transition duration-300"
        :class="[
          activeIndex != null ? 'aspect-square' : 'aspect-[4/5] w-52',
          { 'scale-90': activeIndex === index + 1 },
        ]"
        :alt="service.title"
      >

      <div
        class="flex-auto group-hover:scale-90 group-hover:translate-y-2 transition duration-300"
        :class="[
          activeIndex != null ? '' : 'group-hover:-translate-x-9',
          { 'scale-90 translate-y-2': activeIndex === index + 1 },
        ]"
      >
        <h3
          class="text-lg font-semibold leading-6 tracking-tight text-[#044E4F] min-w-40"
        >
          {{ service.title }}
        </h3>

        <p
          class="mt-6 text-base leading-7 text-gray-600"
          :class="{ hidden: activeIndex != null }"
        >
          {{ service.description }}
        </p>
      </div>
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  services: {
    type: Array,
    required: true
  },
  activeIndex: {
    type: Number,
    default: null
  }
});

const emit = defineEmits([ 'update:activeIndex', 'scroll' ]);

const localActiveIndex = ref(props.activeIndex);

function toggle(index) {
  localActiveIndex.value = localActiveIndex.value === index ? null : index;
  emit('update:activeIndex', localActiveIndex.value);
  emit('scroll');
}

watch(
  () => props.activeIndex,
  newVal => {
    localActiveIndex.value = newVal;
  }
);
</script>
