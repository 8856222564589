<template>
  <div>
    <a
      v-for="link in links"
      :key="link.id"
      :href="link.href"
      class="hover:opacity-70 transition-opacity"
    >
      <span class="sr-only">{{ link.text }}</span>
      <font-awesome-icon :icon="link.icon" class="h-6 w-6" aria-hidden="true" />
    </a>
  </div>
</template>

<script setup>
const links = [
  { id: 'email', text: 'Email', href: 'mailto:info@greenbim.com', icon: 'fa-solid fa-envelope' },
  { id: 'youtube', text: 'YouTube', href: 'https://www.youtube.com/@GreenBIMdotcom/featured', icon: 'fa-brands fa-youtube' },
  { id: 'linkedin', text: 'LinkedIn', href: 'https://www.linkedin.com/company/green-bim-llc', icon: 'fa-brands fa-linkedin' },
  { id: 'twitter', text: 'Twitter', href: 'https://twitter.com/', icon: 'fa-brands fa-x-twitter' }
];
</script>
