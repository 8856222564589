<template>
  <div>
    <component
      :is="component"
      v-for="component in components"
      :key="component.name"
      v-intersection-observer="[
        onIntersectionObserver,
        { root: target, threshold: 0.3 },
      ]"
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import Hero from '../components/Hero.vue';
import Carousel from '../components/Carousel.vue';
import About from '../components/About.vue';
import StepsD from '../components/StepsD.vue';
import Consumer from '../components/Consumer.vue';
import ContactUs from '../components/ContactUs.vue';
import Services from '../components/Services.vue';
import ForModular from '../components/ForModular.vue';

import { vIntersectionObserver } from '@vueuse/components';

const router = useRouter();
watch(
  () => router.currentRoute.value.hash,
  hash => {
    if (hash) {
      setTimeout(() => {
        document.getElementById(hash.slice(1))?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 10);
    }
  },
  { immediate: true }
);

const target = ref(null);

const emit = defineEmits([ 'update:modelValue' ]);

const components = [
  Carousel,
  Hero,
  StepsD,
  Services,
  ForModular,
  Consumer,
  About,
  ContactUs
];

function onIntersectionObserver([ { isIntersecting, target: _target } ]) {
  if (isIntersecting) {
    emit('update:modelValue', _target?.id || null);
  }
}
</script>
