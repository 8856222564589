<template>
  <div class="relative w-full overflow-hidden">
    <img class="w-full" :src="afterImage" alt="After slide">
    <div
      class="absolute inset-0 z-20 cursor-ew-resize"
      @mousedown="startDragging"
      @touchstart="startDragging"
    />
    <div
      class="jx-handle absolute top-0 z-10 w-[4px] cursor-ew-resize bg-white"
      :style="{ left: handlePosition + '%' }"
    >
      <div
        class="flex items-center justify-center bg-white rounded-full w-10 h-10 relative"
        style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
      >
        <div class="jx-arrow jx-left" />
        <div class="jx-arrow jx-right" />
      </div>
    </div>

    <div class="jx-image" :style="{ width: handlePosition + '%' }">
      <img :src="beforeImage" alt="Before slide">
    </div>
  </div>
</template>

<script>
export default {
  name: 'JXSlider',

  props: {
    beforeImage: {
      type: String,
      required: true
    },

    afterImage: {
      type: String,
      required: true
    }
  },

  data: () => ({
    dragging: false,
    handlePosition: 50
  }),

  methods: {
    startDragging(event) {
      event.preventDefault();
      this.dragging = true;
      this.calculatePosition(event);

      const moveEvent = event.type === 'mousedown' ? 'mousemove' : 'touchmove';
      const stopEvent = event.type === 'mousedown' ? 'mouseup' : 'touchend';

      const onMouseMove = e => {
        this.calculatePosition(e);
      };

      const stopDragging = () => {
        document.removeEventListener(moveEvent, onMouseMove);
        document.removeEventListener(stopEvent, stopDragging);
        this.dragging = false;
      };

      document.addEventListener(moveEvent, onMouseMove);
      document.addEventListener(stopEvent, stopDragging);
    },

    calculatePosition(e) {
      const slider = this.$el.getBoundingClientRect();
      const x = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
      const position = ((x - slider.left) / slider.width) * 100;
      if (position >= 0 && position <= 100) {
        this.handlePosition = position;
      }
    }
  }
};
</script>

<style lang="scss">
.jx-handle {
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 40px;
  cursor: col-resize;

  .jx-arrow {
    position: absolute;
    background-repeat: no-repeat;
    border: none;
    height: 24px;
    width: 24px;
    will-change: transform;
    transition: all 0.2s ease;
    z-index: 1;

    &.jx-right {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwLjYgNiA5LjQgN2w0LjYgNS00LjYgNSAxLjIgMSA1LjQtNnoiLz48L3N2Zz4=);
      right: 0;
    }

    &.jx-left {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEzLjQgMTggOCAxMmw1LjQtNiAxLjIgMS00LjYgNSA0LjYgNXoiLz48L3N2Zz4=);
      left: 0;
    }
  }

  &:hover {
    .jx-arrow {
      &.jx-right {
        right: -2px;
      }

      &.jx-left {
        left: -2px;
      }
    }
  }
}

.jx-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;

  img {
    max-width: none;
    height: 100%;
  }
}
</style>
