<template>
  <section id="carousel" class="mb-40">
    <Carousel
      :autoplay="5000"
      :wrap-around="true"
      pause-autoplay-on-hover
    >
      <Slide v-for="slide in slides" :key="slide.id">
        <div class="carousel__item !w-full relative min-h-80">
          <img :src="slide.imageUrl" alt="Slide" class="w-full h-full min-h-80 object-cover blur-[2px] brightness-[0.6]">
          <div class="absolute inset-0 flex justify-center items-center">
            <div class="w-5/6 mx-auto text-white drop-shadow-[0_0_15px_rgba(0,0,0,0.3)] space-y-2 md:space-y-10">
              <div class="[font-size:_clamp(1.4rem,1.8vw+1rem,4.5rem)] font-bold text-balance text-shadow leading-snug">
                {{ slide.text }}
              </div>
              <div class="[font-size:_clamp(0.3rem,1.2vw+0.75rem,3rem)] font-semibold text-balance text-shadow leading-tight">
                {{ slide.subtext }}
              </div>
              <div class="text-center">
                <router-link :to="'/' + slide.href" class="btn-primary">
                  Explore
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation class="text-white hover:!text-white/60 drop-shadow-[0_0_15px_rgba(0,0,0,0.3)]" />
        <Pagination />
      </template>
    </Carousel>
  </section>
</template>

<script setup>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import slideOneUrl from '../assets/images/slide-1.webp';
import slideTwoUrl from '../assets/images/slide-4.webp';

const slides = [
  {
    id: 1,
    imageUrl: slideOneUrl,
    text: 'Digital Twin at the click of a button.',
    subtext: 'With the creation of a digital twin Facilities Management gets a bigger seat at the table to adding value for the bottom line.',
    href: '#services'
  },
  {
    id: 2,
    imageUrl: slideTwoUrl,
    text: 'You can update the tools in your offsite construction operation, but upgrading your data with BIM yields a greater ROI.',
    subtext: 'If your modular operation isn’t using BIM, you’re not fully leveraging the benefits of the data you build in the offsite construction process.',
    href: '#for-modular'
  }
];
</script>
